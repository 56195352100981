export const vehicles = {
  "Acura": {
    models: ["ILX", "MDX", "NSX", "RDX", "TLX", "Integra", "RSX", "TSX", "ZDX", "RL", "RLX", "CL", "Legend", "Vigor"],
    years: [1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Alfa Romeo": {
    models: ["4C", "Giulia", "Giulietta", "Stelvio", "Tonale", "Spider", "GTV", "164", "156", "147", "159", "Brera", "8C Competizione", "MiTo"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Aston Martin": {
    models: ["DB9", "DB11", "DBS", "Vantage", "Rapide", "Vanquish", "DBX", "Valkyrie", "DB12", "Valhalla", "DB7", "Virage", "One-77", "Vulcan"],
    years: [1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Audi": {
    models: ["A3", "A4", "A5", "A6", "A7", "A8", "Q3", "Q5", "Q7", "Q8", "TT", "R8", "RS3", "RS4", "RS5", "RS6", "RS7", "RSQ8", "e-tron", "e-tron GT"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Bentley": {
    models: ["Continental GT", "Flying Spur", "Bentayga", "Mulsanne", "Azure", "Arnage", "Brooklands", "Continental GTC", "Continental GT Speed", "Continental Supersports"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "BMW": {
    models: ["1 Series", "2 Series", "3 Series", "4 Series", "5 Series", "6 Series", "7 Series", "8 Series", "M2", "M3", "M4", "M5", "M6", "M8", "X1", "X2", "X3", "X4", "X5", "X6", "X7", "Z3", "Z4", "i3", "i4", "i7", "i8", "iX"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Bugatti": {
    models: ["Veyron", "Chiron", "Divo", "Centodieci", "La Voiture Noire", "Mistral", "EB110", "Type 57"],
    years: [1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Buick": {
    models: ["Enclave", "Encore", "Envision", "LaCrosse", "Regal", "Verano", "Century", "LeSabre", "Park Avenue", "Rendezvous", "Terraza", "Lucerne", "Rainier"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Cadillac": {
    models: ["CT4", "CT5", "Escalade", "XT4", "XT5", "XT6", "ATS", "CTS", "DTS", "ELR", "SRX", "XTS", "DeVille", "Eldorado", "Seville", "STS", "XLR"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Chevrolet": {
    models: ["Blazer", "Camaro", "Colorado", "Corvette", "Cruze", "Equinox", "Impala", "Malibu", "Silverado", "Suburban", "Tahoe", "Traverse", "Trax", "Volt", "Bolt", "Spark", "Sonic", "Express", "Avalanche"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Chrysler": {
    models: ["300", "Pacifica", "Voyager", "200", "Town & Country", "PT Cruiser", "Sebring", "Crossfire", "Aspen", "Concorde", "LHS", "New Yorker", "Cirrus"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Citroën": {
    models: ["C3", "C4", "C5", "Berlingo", "SpaceTourer", "DS3", "DS4", "DS5", "C1", "C2", "C6", "C8", "Xsara", "Saxo", "Picasso", "Jumpy"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Dacia": {
    models: ["Duster", "Sandero", "Logan", "Spring", "Jogger", "Lodgy", "Dokker", "Nova", "Solenza", "Supernova"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Daewoo": {
    models: ["Lanos", "Nubira", "Leganza", "Matiz", "Kalos", "Lacetti", "Espero", "Nexia", "Tacuma", "Magnus", "Evanda"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008]
  },
  "Daihatsu": {
    models: ["Terios", "Sirion", "Materia", "Copen", "YRV", "Charade", "Cuore", "Move", "Rocky", "Feroza", "Applause"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013]
  },
  "Dodge": {
    models: ["Challenger", "Charger", "Durango", "Journey", "Grand Caravan", "Ram", "Viper", "Avenger", "Caliber", "Nitro", "Magnum", "Neon", "Dakota", "Dart"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Ferrari": {
    models: ["F8 Tributo", "SF90 Stradale", "296 GTB", "Roma", "Portofino", "812 Superfast", "F12", "458", "488", "LaFerrari", "Enzo", "F40", "F50", "599", "California"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Fiat": {
    models: ["500", "500X", "Panda", "Tipo", "124 Spider", "500L", "Punto", "Bravo", "Stilo", "Multipla", "Seicento", "Doblo", "Qubo", "Ducato"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Ford": {
    models: ["Bronco", "Edge", "Escape", "Explorer", "F-150", "Focus", "Fusion", "Mustang", "Ranger", "Transit", "Expedition", "EcoSport", "Fiesta", "Flex", "GT", "Taurus"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Genesis": {
    models: ["G70", "G80", "G90", "GV70", "GV80", "GV60"],
    years: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "GMC": {
    models: ["Acadia", "Canyon", "Sierra", "Terrain", "Yukon", "Savana", "Envoy", "Jimmy", "Safari", "Sonoma"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Honda": {
    models: ["Accord", "Civic", "CR-V", "HR-V", "Odyssey", "Pilot", "Ridgeline", "Fit", "Element", "Insight", "Passport", "S2000", "Prelude", "Del Sol"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Hummer": {
    models: ["H1", "H2", "H3", "EV"],
    years: [1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2022, 2023, 2024]
  },
  "Hyundai": {
    models: ["Accent", "Elantra", "Ioniq", "Kona", "Palisade", "Santa Fe", "Sonata", "Tucson", "Veloster", "Venue", "Genesis", "Azera", "Entourage", "Equus", "Tiburon", "XG350"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Infiniti": {
    models: ["Q50", "Q60", "QX50", "QX55", "QX60", "QX80", "G35", "G37", "FX35", "FX45", "M35", "M45", "QX4", "QX56", "I30", "I35", "J30"],
    years: [1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Isuzu": {
    models: ["Trooper", "Rodeo", "Axiom", "Ascender", "VehiCROSS", "Amigo", "Oasis", "Hombre", "i-280", "i-290", "i-350", "i-370"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008]
  },
  "Jaguar": {
    models: ["E-PACE", "F-PACE", "F-TYPE", "I-PACE", "XE", "XF", "XJ", "S-Type", "X-Type", "XK", "XK8", "XKR"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Jeep": {
    models: ["Cherokee", "Compass", "Gladiator", "Grand Cherokee", "Renegade", "Wrangler", "Commander", "Liberty", "Patriot", "Wagoneer", "Grand Wagoneer"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Kia": {
    models: ["Forte", "K5", "Niro", "Rio", "Seltos", "Sorento", "Soul", "Sportage", "Stinger", "Telluride", "Cadenza", "Optima", "Sedona", "Spectra", "Amanti", "Borrego"],
    years: [1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Koenigsegg": {
    models: ["Agera", "Jesko", "Gemera", "Regera", "CC8S", "CCR", "CCX", "CCXR", "One:1"],
    years: [2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Lamborghini": {
    models: ["Aventador", "Huracan", "Urus", "Gallardo", "Murcielago", "Diablo", "Countach", "Reventon", "Veneno", "Sian", "LM002"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Lancia": {
    models: ["Delta", "Ypsilon", "Thema", "Thesis", "Phedra", "Musa", "Lybra", "Kappa", "Dedra", "Zeta"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017]
  },
  "Land Rover": {
    models: ["Defender", "Discovery", "Range Rover", "Range Rover Sport", "Range Rover Velar", "Range Rover Evoque", "Discovery Sport", "Freelander", "LR2", "LR3", "LR4"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Lexus": {
    models: ["ES", "GS", "GX", "IS", "LC", "LS", "LX", "NX", "RC", "RX", "UX", "CT", "HS", "SC"],
    years: [1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Lincoln": {
    models: ["Aviator", "Corsair", "Navigator", "Nautilus", "Continental", "MKC", "MKS", "MKT", "MKX", "MKZ", "Town Car", "LS", "Mark LT", "Zephyr"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Lotus": {
    models: ["Elise", "Evora", "Exige", "Emira", "Esprit", "Europa", "340R", "2-Eleven", "Evija"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Maserati": {
    models: ["Ghibli", "Levante", "Quattroporte", "MC20", "GranTurismo", "GranCabrio", "Coupe", "Spyder", "Bora", "Merak", "Shamal", "3200 GT"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Mazda": {
    models: ["3", "6", "CX-3", "CX-30", "CX-5", "CX-9", "MX-5 Miata", "RX-7", "RX-8", "Tribute", "MPV", "Protege", "626", "Millenia"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "McLaren": {
    models: ["720S", "765LT", "Artura", "GT", "P1", "Senna", "Speedtail", "570S", "600LT", "650S", "675LT", "MP4-12C"],
    years: [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Mercedes-Benz": {
    models: ["A-Class", "B-Class", "C-Class", "CLA", "CLS", "E-Class", "EQS", "G-Class", "GLA", "GLB", "GLC", "GLE", "GLS", "S-Class", "SL", "SLC", "AMG GT", "Maybach S-Class"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Mini": {
    models: ["Cooper", "Clubman", "Countryman", "Paceman", "Coupe", "Roadster", "Convertible", "Hardtop", "John Cooper Works"],
    years: [2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Mitsubishi": {
    models: ["Eclipse Cross", "Outlander", "Outlander Sport", "Mirage", "Lancer", "Eclipse", "Galant", "Endeavor", "Montero", "Diamante", "3000GT", "Raider"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Nissan": {
    models: ["Altima", "Armada", "Frontier", "GT-R", "Kicks", "Leaf", "Maxima", "Murano", "Pathfinder", "Rogue", "Sentra", "Titan", "Versa", "370Z", "350Z", "Xterra", "Cube", "Juke"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Pagani": {
    models: ["Huayra", "Zonda", "Utopia"],
    years: [1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Peugeot": {
    models: ["208", "2008", "308", "3008", "508", "5008", "Rifter", "Partner", "Expert", "Boxer", "RCZ", "407", "607"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Porsche": {
    models: ["911", "718 Boxster", "718 Cayman", "Cayenne", "Macan", "Panamera", "Taycan", "918 Spyder", "Carrera GT", "944", "928", "968"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Ram": {
    models: ["1500", "2500", "3500", "ProMaster", "ProMaster City", "Dakota", "Cargo Van"],
    years: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Renault": {
    models: ["Clio", "Captur", "Megane", "Scenic", "Kadjar", "Koleos", "Talisman", "Twingo", "Zoe", "Espace", "Laguna", "Vel Satis"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Rolls-Royce": {
    models: ["Phantom", "Ghost", "Wraith", "Dawn", "Cullinan", "Silver Shadow", "Silver Spur", "Corniche", "Silver Seraph"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Saab": {
    models: ["9-3", "9-5", "900", "9000", "9-2X", "9-4X", "9-7X"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011]
  },
  "Saturn": {
    models: ["Ion", "Vue", "Aura", "Outlook", "Sky", "Relay", "L-Series", "S-Series"],
    years: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009]
  },
  "Scion": {
    models: ["tC", "xB", "xD", "FR-S", "iQ", "xA"],
    years: [2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
  },
  "Seat": {
    models: ["Ibiza", "Leon", "Ateca", "Arona", "Tarraco", "Alhambra", "Toledo", "Mii", "Altea", "Exeo"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Škoda": {
    models: ["Octavia", "Superb", "Kodiaq", "Karoq", "Kamiq", "Scala", "Fabia", "Rapid", "Yeti", "Roomster"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Smart": {
    models: ["ForTwo", "ForFour", "Roadster", "Crossblade"],
    years: [1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Subaru": {
    models: ["Ascent", "BRZ", "Crosstrek", "Forester", "Impreza", "Legacy", "Outback", "WRX", "Baja", "Tribeca", "SVX", "Justy"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Suzuki": {
    models: ["Swift", "Vitara", "S-Cross", "Jimny", "Ignis", "Grand Vitara", "SX4", "Kizashi", "Aerio", "Esteem", "Samurai", "Sidekick"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Tesla": {
    models: ["Model 3", "Model S", "Model X", "Model Y", "Cybertruck", "Roadster"],
    years: [2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Toyota": {
    models: ["4Runner", "Avalon", "Camry", "Corolla", "Highlander", "Land Cruiser", "Prius", "RAV4", "Sequoia", "Sienna", "Tacoma", "Tundra", "Supra", "86", "Matrix", "Celica", "MR2"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Volkswagen": {
    models: ["Arteon", "Atlas", "Golf", "ID.4", "Jetta", "Passat", "Tiguan", "Touareg", "Beetle", "CC", "Eos", "Phaeton", "Rabbit", "Routan"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  },
  "Volvo": {
    models: ["C30", "C40", "S60", "S90", "V60", "V90", "XC40", "XC60", "XC90", "240", "740", "850", "940", "960", "S40", "V40", "S70", "V70"],
    years: [1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  }
} as const;